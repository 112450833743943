
import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Cookies from "js-cookie";
import CompanyRegistrationCompanyData from "@/types/company-registration-company-data";
import municipios from "@/data/municipios";
import { SegmentsAvailableForSelfRegister } from "@/data/segmentos";
import CompanyService, {
  UpdateRegisterDataParams
} from "@/services/company-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import CompanyRegistrationHeader from "@/components/company-registration/CompanyRegistrationHeader.vue";
import CompanyRegistrationFooter from "@/components/company-registration/CompanyRegistrationFooter.vue";
import SaveButton from "@/components/SaveButton.vue";
import UploadDialog from "@/components/UploadDialog.vue";
import TermsDialog from "@/components/TermsDialog.vue";
import AlertBox from "@/components/AlertBox.vue";
import CompanyRegistrationEventBus from "@/events/company-registration-event-bus";
import Segment from "@/types/segment";
import { calculateDefaultPaymentCutDay } from "@/utils/calculateDefaultPaymentCutDay";
import getErrorMessageForLandlineOrMobilePhoneValidation from "@/utils/getErrorMessageForLandlineOrMobilePhoneValidation";

const DEFAULT_PARTNER_ID_IDENTIFIER = "partner";

@Component({
  components: {
    ValidationObserver,
    CompanyRegistrationHeader,
    CompanyRegistrationFooter,
    UploadDialog,
    TermsDialog,
    SaveButton,
    AlertBox
  }
})
export default class CompanyData extends Vue {
  getErrorMessageForLandlineOrMobilePhoneValidation =
    getErrorMessageForLandlineOrMobilePhoneValidation;
  cities: string[] = [];
  states = municipios.estados;
  segments = SegmentsAvailableForSelfRegister;
  loading: boolean = false;
  acceptTerms: boolean = false;
  showTermsError: boolean = false;
  form: CompanyRegistrationCompanyData & { segment: Partial<Segment> | null } =
    {
      id: undefined,
      name: "",
      cnpj: "",
      segment: null,
      segmentName: null,
      segmentId: null,

      address: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      payCutDay: "",
      expirationDay: "",
      paymentDay: "",

      partnerIdentifier: undefined
    };
  companyService: CompanyService;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
  }

  created(): void {
    this.loadCities();
  }

  async mounted(): Promise<void> {
    const [companyRegisterDataError, companyRegisterData] =
      await this.companyService.getCompanyRegisterData();
    if (companyRegisterDataError) {
      this.stopLoading();
      const message = getErrorMessageFromApiError(companyRegisterDataError);
      this.$notify({
        title: "Erro ao obter dados já salvos",
        type: "error",
        text: message
      });
    }

    if (companyRegisterData) {
      this.form = {
        id: companyRegisterData.company.id,
        name: companyRegisterData.company.name,
        cnpj: companyRegisterData.company.cnpj,
        segment:
          this.segments.find(
            (s) => s.id === companyRegisterData.company.segmentId
          ) || null,
        segmentName: companyRegisterData.company.segmentName,
        segmentId: companyRegisterData.company.segmentId,

        address: companyRegisterData.company.address,
        number: companyRegisterData.company.number,
        complement: companyRegisterData.company.complement,
        district: companyRegisterData.company.district,
        city: companyRegisterData.company.city,
        state: companyRegisterData.company.state,
        zipCode: companyRegisterData.company.zipCode,
        phone: companyRegisterData.company.phone,

        payCutDay: companyRegisterData.partnership.payCutDay,
        expirationDay: companyRegisterData.partnership.expirationDay,
        paymentDay: companyRegisterData.partnership.paymentDay
      };
      this.loadCities();
    }
  }

  loadCities(): void {
    if (this.form.state) {
      this.cities = municipios.cidadePorEstado(this.form.state);
    } else {
      this.cities = [];
    }
    if (this.form.city && !this.cities.includes(this.form.city)) {
      this.form.city = "";
    }
  }

  async save(): Promise<void> {
    if (this.acceptTerms) {
      this.startLoading();
      const form = { ...this.form };
      form.number = form.number.replace(/[^\d]+/g, "");
      form.cnpj = form.cnpj.replace(/[^\d]+/g, "");
      form.zipCode = form.zipCode.replace(/[^\d]+/g, "");
      form.partnerIdentifier = this.getPartnerId();

      const shouldCreateCompany = form.id === undefined;
      const shouldUpdateCompany = form.id !== undefined;

      let companyError, company;

      if (shouldCreateCompany) {
        [companyError, company] = await this.companyService.registerCompany(
          form
        );
      } else if (shouldUpdateCompany) {
        [companyError, company] = await this.companyService.updateRegisterData(
          form as UpdateRegisterDataParams
        );
      }

      if (company) {
        this.stopLoading();
        this.goToNextStep({
          companyId: company.id
        });
      } else if (companyError) {
        this.stopLoading();
        const message = getErrorMessageFromApiError(companyError);
        this.$notify({
          title: "Erro ao registrar empresa",
          type: "error",
          text: message
        });
      }
    } else {
      this.showTermsError = true;
    }
  }

  expirationDayChanged(): void {
    if (this.form.expirationDay) {
      const expirationDay = Number(this.form.expirationDay);

      this.form.payCutDay =
        calculateDefaultPaymentCutDay(expirationDay).toString();
    }
  }

  getPartnerId(): string | undefined {
    const partnerIdFromCookies = this.getPartnerIdFromCookies();
    const partnerIdFromQueryParams = this.getPartnerIdFromQueryParams();
    if (partnerIdFromQueryParams) {
      return partnerIdFromQueryParams;
    } else if (partnerIdFromCookies) {
      return partnerIdFromCookies;
    }
  }

  getPartnerIdFromQueryParams(): string | undefined {
    const partnerId = this.$route.query[
      DEFAULT_PARTNER_ID_IDENTIFIER
    ] as string;
    return partnerId;
  }

  getPartnerIdFromCookies(): string | undefined {
    const partnerId = Cookies.get(DEFAULT_PARTNER_ID_IDENTIFIER);
    return partnerId;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  goToNextStep({ companyId }: { companyId: number }): void {
    CompanyRegistrationEventBus.$emit(
      "companyRegistrationState",
      "partnersData",
      { partnersDataConfig: { companyId } }
    );
  }

  goToLogin(): void {
    this.$router.push("/entrar");
  }
}
